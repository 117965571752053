import { Steps } from "antd";
import * as React from "react";
import { truncateText } from "../../../utils";

const StatusView = ({ data }: any) => {
  // console.log(data);
  return (
    <div className="pr-6">
      <Steps
        progressDot
        current={1}
        direction="vertical"
        items={data.map((x: any) => {
          return {
            title: x.title,
            description: truncateText(x.description, 20),
            status: "finish",
          };
        })}
      />
    </div>
  );
};
export default StatusView;
