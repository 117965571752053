import * as React from "react";
import { Card } from "../../atoms";
import { truncateText } from "../../utils";
import { langData } from "./data";
import GameView from "./game/game";

const LearnView = ({ data }: any) => {
  const languages = langData.map((x) => x.language);

  const [selectedLang, setSelectedLang] = React.useState(0);
  const [selectedTarget, setSelectedTarget] = React.useState(0);
  const language = langData[selectedLang];
  const target = language.targets[selectedTarget];

  const languagesList = languages.map((lang: any, i: number) => {
    return (
      <div key={"langnamerow" + i} className="">
        <Card
          onClick={() => setSelectedLang(i)}
          title={lang}
          active={selectedLang === i}
          className="p-3 inline-block px-6"
        />
      </div>
    );
  });

  const targetList = language.targets.map((x: any, i: number) => {
    const selected = selectedTarget === i;
    return (
      <div key={"targetrow" + i}>
        <div
          role={"button"}
          className={`  px-3 p-1 rounded text-sm   ${
            selected ? " bg-accent text-white " : "bg-secondary  text-primary"
          }`}
          onClick={() => {
            setSelectedTarget(i);
          }}
        >
          {x.language}
          <span className={`ml-2 ${selected ? "text-white" : "text-accent"}`}>
            ({x.modules.length})
          </span>
        </div>
      </div>
    );
  });

  const targetsView = () => {
    return (
      <div>
        {" "}
        <GameView data={language.targets[selectedTarget]?.modules} />
      </div>
    );
  };

  return (
    <div className=" mt-4">
      {/* <div>{languagesList}</div> */}
      <div className="mt-2 inline-flex gap-2 flex-wrap">{targetList}</div>
      <div className="mt-4">{targetsView()}</div>
    </div>
  );
};
export default LearnView;
