import * as React from "react";
import StatusView from "./status";
import GameWordsView from "./words";

const GamesView = ({ data }: any) => {
  const [selectedModule, setSelectedModule] = React.useState<number | null>(
    null
  );
  // const [gameWords, setGameWords] = React.useState([]);

  const serverUrl = process.env.GATSBY_API_URL;

  React.useEffect(() => {
    setSelectedModule(null);
    // console.log();
  }, [data]);
  const gameData: any[] = data[selectedModule] || [];

  const gameGallery = data.map((x: any, i: number) => {
    const isSelected = selectedModule === i;
    return (
      <div
        className={`w-full relative hover:scale-110 hover:z-50 shadow-md  hover: border-accent duration-300  rounded ${
          isSelected ? "  z-50 " : "z-0"
        }`}
        key={"gameGalleryrow" + i}
        role="button"
        onClick={() => {
          setSelectedModule(i);
          // setGameWords(x.words);
        }}
      >
        <img
          className={`object-cover rounded`}
          src={
            `${serverUrl}api/files/data/course/images/` +
            (x.image ? x.image : `lake.jpeg`)
          }
        />
        <div
          className={`bottom-0 text-white rounded-b w-full  absolute p-2 ${
            isSelected
              ? "bg-accent text-white bg-opacity-90"
              : "bg-gray-900  bg-opacity-90 text-primary"
          }`}
        >
          <div className="">{x.title}</div>
          <div
            // style={{ minHeight: "40px" }}
            className="  line-clamp-2 mt-1 text-xs"
          >
            {x.description}
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className=" mt-6">
      <div className="flex flex-row  ">
        <div className="px-2 w-36">
          <StatusView
            data={data.map((x: any) => {
              return { title: x.title, description: x.description };
            })}
          />
        </div>
        <div className="flex-1 gap-4 columns-1 sm:columns-2 md:columns-3 lg:columns-4">
          {gameGallery}
        </div>
      </div>

      <div className="mt-10">
        {gameData && <GameWordsView gameData={gameData} />}
      </div>
    </div>
  );
};
export default GamesView;
