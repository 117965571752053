import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { message } from "antd";
import * as React from "react";
import { LoadBox } from "./atoms";
import { IStatus } from "./types";

const SubScribeView = ({ data }: any) => {
  const serverUrl = process.env.GATSBY_API_URL;
  const [loading, setLoading] = React.useState(false);
  const [response, setResponse] = React.useState<IStatus | null>(null);

  const emailInputRef = React.useRef<HTMLInputElement>(null);

  const subscribe = (email: string) => {
    setResponse(null);
    const re = /\S+@\S+\.\S+/;
    if (!email || !re.test(email)) {
      setResponse({
        status: false,
        message: "Please enter a valid email address.",
      });
      return;
    }

    // validate email

    setLoading(true);
    const subscribeUrl = `${serverUrl}api/subscribe`;
    fetch(subscribeUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    })
      .then((res) => {
        setLoading(false);
        return res.json();
      })
      .then((data) => {
        // console.log(data);
        setResponse(data);
        emailInputRef.current!.value = "";
      })
      .catch((err) => {
        setLoading(false);
        setResponse({
          status: false,
          message: "Something went wrong. Please try again.",
        });
      });
  };

  return (
    <div className=" mt-2">
      <div className="mt-2 mb-2 text-sm">
        {" "}
        <ExclamationTriangleIcon className="inline-block h-5 mr-1 text-accent" />{" "}
        Currently in beta. Stay tuned!
      </div>
      <div>
        <input
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              subscribe(emailInputRef.current!.value);
            }
          }}
          ref={emailInputRef}
          className="w-72 rounded-sm text-gray-600 border border-r-0 rounded-r-none p-2"
          type={"email"}
          placeholder={"Enter your email"}
        />
        <button
          onClick={() => {
            subscribe(emailInputRef.current!.value);
          }}
          className="bg-accent border border-accent text-white px-4 py-2 rounded-sm rounded-l-none"
        >
          {loading && <LoadBox className="inline-block " />} Stay Updated
        </button>
      </div>
      <div
        className={`text-sm mt-2 ${
          response?.status ? "text-green-600" : "text-red-600"
        }`}
      >
        {response?.message}
      </div>
    </div>
  );
};
export default SubScribeView;
