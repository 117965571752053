export const langData = [
  {
    language: "English",
    targets: [
      {
        language: "Spanish",
        modules: [
          {
            difficulty: "easy",
            title: "Greetings",
            description: "Learn how to greet people in Spanish",
            words: [
              {
                word: "hola",
                description: "hello",
                sentences: [
                  {
                    sentence: "Hola, ¿cómo estás?",
                    translation: "Hello, how are you?",
                  },
                  {
                    sentence: "Hola, ¿Qué hora es?",
                    translation: "Hello, what time is it?",
                  },
                ],
              },
              {
                word: "adiós",
                description: "goodbye",
                sentences: [
                  {
                    sentence: "Adiós, hasta luego",
                    translation: "Goodbye, see you later",
                  },
                  {
                    sentence: "Adiós, hasta mañana",
                    translation: "Goodbye, see you tomorrow",
                  },
                ],
              },
            ],
          },
          {
            difficulty: "hard",
            title: "Animals",
            image: "cat.jpeg",
            description: "Learn the names of animals in Spanish",
            words: [
              {
                word: "gato",
                description: "cat",
                sentences: [
                  {
                    sentence: "Tengo un gato",
                    translation: "I have a cat",
                  },
                  {
                    sentence: "Mi gato es gris",
                    translation: "My cat is gray",
                  },
                ],
              },
              {
                word: "perro",
                description: "dog",
                sentences: [
                  {
                    sentence: "Tengo un perro",
                    translation: "I have a dog",
                  },
                  {
                    sentence: "Mi perro es marrón",
                    translation: "My dog is brown",
                  },
                ],
              },
              {
                word: "pájaro",
                description: "bird",
                sentences: [
                  {
                    sentence: "He visto un pájaro",
                    translation: "I have seen a bird",
                  },
                  {
                    sentence: "Mi pájaro es amarillo",
                    translation: "My bird is yellow",
                  },
                ],
              },
            ],
          },
          {
            difficulty: "medium",
            title: "Numbers",
            image: "number.jpeg",
            description: "Learn how to count in Spanish",
            words: [
              {
                word: "uno",
                description: "one",
                sentences: [
                  {
                    sentence: "Uno, dos, tres",
                    translation: "One, two, three",
                  },
                  {
                    sentence: "Uno, dos, tres, cuatro, cinco",
                    translation: "One, two, three, four, five",
                  },
                ],
              },
              {
                word: "dos",
                description: "two",
                sentences: [
                  {
                    sentence: "Uno, dos, tres",
                    translation: "One, two, three",
                  },
                  {
                    sentence: "Uno, dos, tres, cuatro, cinco",
                    translation: "One, two, three, four, five",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        language: "French",
        modules: [
          {
            difficulty: "easy",
            title: "Greetings",
            description: "Learn how to greet people in French",
            words: [
              {
                word: "bonjour",
                description: "hello",
                sentences: [
                  {
                    sentence: "Bonjour, comment vas-tu?",
                    translation: "Hello, how are you?",
                  },
                  {
                    sentence: "Bonjour, quelle heure est-il?",
                    translation: "Hello, what time is it?",
                  },
                ],
              },
              {
                word: "au revoir",
                description: "goodbye",
                sentences: [
                  {
                    sentence: "Au revoir, à bientôt",
                    translation: "Goodbye, see you soon",
                  },
                  {
                    sentence: "Au revoir, à demain",
                    translation: "Goodbye, see you tomorrow",
                  },
                ],
              },
            ],
          },
          {
            difficulty: "medium",
            title: "Numbers",
            description: "Learn how to count in French",
            words: [
              {
                word: "un",
                description: "one",
                sentences: [
                  {
                    sentence: "Un, deux, trois",
                    translation: "One, two, three",
                  },
                  {
                    sentence: "Un, deux, trois, quatre, cinq",
                    translation: "One, two, three, four, five",
                  },
                ],
              },
              {
                word: "deux",
                description: "two",
                sentences: [
                  {
                    sentence: "Un, deux, trois",
                    translation: "One, two, three",
                  },
                  {
                    sentence: "Un, deux, trois, quatre, cinq",
                    translation: "One, two, three, four, five",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        language: "German",
        modules: [
          {
            difficulty: "easy",
            title: "Greetings",
            description: "Learn how to greet people in German",
            words: [
              {
                word: "Hallo",
                description: "hello",
                sentences: [
                  {
                    sentence: "Hallo, wie geht es dir?",
                    translation: "Hello, how are you?",
                  },
                  {
                    sentence: "Hallo, wie spät ist es?",
                    translation: "Hello, what time is it?",
                  },
                ],
              },
              {
                word: "Auf Wiedersehen",
                description: "goodbye",
                sentences: [
                  {
                    sentence: "Auf Wiedersehen, bis später",
                    translation: "Goodbye, see you later",
                  },
                  {
                    sentence: "Auf Wiedersehen, bis morgen",
                    translation: "Goodbye, see you tomorrow",
                  },
                ],
              },
            ],
          },
          {
            difficulty: "hard",
            title: "Animals",
            image: "cat.jpeg",
            description: "Learn the names of animals in German",
            words: [
              {
                word: "Katze",
                description: "cat",
                sentences: [
                  {
                    sentence: "Ich habe eine Katze",
                    translation: "I have a cat",
                  },
                  {
                    sentence: "Meine Katze ist grau",
                    translation: "My cat is gray",
                  },
                ],
              },
              {
                word: "Hund",
                description: "dog",
                sentences: [
                  {
                    sentence: "Ich habe einen Hund",
                    translation: "I have a dog",
                  },
                  {
                    sentence: "Mein Hund ist braun",
                    translation: "My dog is brown",
                  },
                ],
              },
              {
                word: "Vogel",
                description: "bird",
                sentences: [
                  {
                    sentence: "Ich habe einen Vogel gesehen",
                    translation: "I have seen a bird",
                  },
                  {
                    sentence: "Mein Vogel ist gelb",
                    translation: "My bird is yellow",
                  },
                ],
              },
            ],
          },
          {
            difficulty: "medium",
            title: "Numbers",
            image: "number.jpeg",
            description: "Learn how to count in German",
            words: [
              {
                word: "eins",
                description: "one",
                sentences: [
                  {
                    sentence: "Eins, zwei, drei",
                    translation: "One, two, three",
                  },
                  {
                    sentence: "Eins, zwei, drei, vier, fünf",
                    translation: "One, two, three, four, five",
                  },
                ],
              },
              {
                word: "zwei",
                description: "two",
                sentences: [
                  {
                    sentence: "Eins, zwei, drei",
                    translation: "One, two, three",
                  },
                  {
                    sentence: "Eins, zwei, drei, vier, fünf",
                    translation: "One, two, three, four, five",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        language: "Chinese",
        modules: [
          {
            difficulty: "easy",
            title: "Greetings",
            description: "Learn how to greet people in Chinese",
            words: [
              {
                word: "你好",
                description: "hello",
                sentences: [
                  {
                    sentence: "你好，你好吗？",
                    translation: "Hello, how are you?",
                  },
                  {
                    sentence: "你好，现在几点？",
                    translation: "Hello, what time is it?",
                  },
                ],
              },
              {
                word: "再见",
                description: "goodbye",
                sentences: [
                  {
                    sentence: "再见，一会见",
                    translation: "Goodbye, see you later",
                  },
                  {
                    sentence: "再见，明天见",
                    translation: "Goodbye, see you tomorrow",
                  },
                ],
              },
            ],
          },
          {
            difficulty: "hard",
            title: "Animals",
            image: "cat.jpeg",
            description: "Learn the names of animals in Chinese",
            words: [
              {
                word: "猫",
                description: "cat",
                sentences: [
                  {
                    sentence: "我有一只猫",
                    translation: "I have a cat",
                  },
                  {
                    sentence: "我的猫是灰色的",
                    translation: "My cat is gray",
                  },
                ],
              },
              {
                word: "狗",
                description: "dog",
                sentences: [
                  {
                    sentence: "我有一只狗",
                    translation: "I have a dog",
                  },
                  {
                    sentence: "我的狗是棕色的",
                    translation: "My dog is brown",
                  },
                ],
              },
              {
                word: "小鸟",
                description: "bird",
                sentences: [
                  {
                    sentence: "我看到一只小鸟",
                    translation: "I have seen a bird",
                  },
                  {
                    sentence: "我的小鸟是黄色的",
                    translation: "My bird is yellow",
                  },
                ],
              },
            ],
          },
          {
            difficulty: "medium",
            title: "Numbers",
            image: "number.jpeg",
            description: "Learn how to count in Chinese",
            words: [
              {
                word: "一",
                description: "one",
                sentences: [
                  {
                    sentence: "一，二，三",
                    translation: "One, two, three",
                  },
                  {
                    sentence: "一，二，三，四，五",
                    translation: "One, two, three, four, five",
                  },
                ],
              },
              {
                word: "二",
                description: "two",
                sentences: [
                  {
                    sentence: "一，二，三",
                    translation: "One, two, three",
                  },
                  {
                    sentence: "一，二，三，四，五",
                    translation: "One, two, three, four, five",
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];
