import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import * as React from "react";

const GameWordsView = ({ gameData }: any) => {
  const words = gameData?.words || [];
  const [selectedWord, setSelectedWord] = React.useState(0);

  const gameBodDivRef = React.useRef<HTMLDivElement>(null);
  const word = words[selectedWord] || words[0];

  React.useEffect(() => {
    console.log("loaded");

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  React.useEffect(() => {
    // scroll gameDev to top of page
    if (gameBodDivRef.current) {
      gameBodDivRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [words]);

  const handleKeyDown = (e: any) => {
    console.log(selectedWord);

    if (e.key == "ArrowRight") {
      updateSelectedWord(true);
    }
    if (e.key == "ArrowLeft") {
      updateSelectedWord(false);
    }
  };

  const updateSelectedWord = (add: boolean) => {
    if (
      (selectedWord === 0 && !add) ||
      (selectedWord === words.length - 1 && add)
    ) {
      return;
    }
    if (add) {
      setSelectedWord(selectedWord + 1);
    } else {
      setSelectedWord(selectedWord - 1);
    }
  };

  const getSentences = ({ sentences }: any) => {
    const sentenceList = sentences.map((sentence: any, i: number) => {
      return (
        <div key={"sentencerow" + i}>
          {sentence.sentence}{" "}
          <span className="ml-2 text-sm text-accent">
            {sentence.translation}
          </span>
        </div>
      );
    });
    return (
      <div className="mt-2 rounded bg-secondary p-3 px-5 ">{sentenceList}</div>
    );
  };
  return (
    <div ref={gameBodDivRef}>
      {words && words.length > 0 && (
        <>
          <div className=" rounded">
            <div>
              {gameData.title}
              <span className="ml-2 text-sm">{gameData.description}</span>
            </div>
            <div>
              {" "}
              Words {selectedWord + 1} / {words.length}
            </div>
            <div className="mt-2 rounded bg-secondary p-3 px-5 ">
              <div
                style={{
                  fontSize: "5rem",
                }}
                className=" "
              >
                {word.word}
              </div>

              <div>{word.description}</div>
            </div>
            <div>{getSentences(word)}</div>
            <div className="gap-2 inline-flex mt-4">
              {selectedWord !== 0 && (
                <div
                  role={"button"}
                  onClick={() => {
                    updateSelectedWord(false);
                  }}
                  className="border p-2 rounded-sm"
                >
                  {" "}
                  <ChevronLeftIcon className="h-5 inline-block" />{" "}
                </div>
              )}
              {selectedWord !== words.length - 1 && (
                <div
                  role={"button"}
                  onClick={() => {
                    updateSelectedWord(true);
                  }}
                  className="border p-2 rounded-sm"
                >
                  {" "}
                  <ChevronRightIcon className="h-5 inline-block" />{" "}
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default GameWordsView;
