import * as React from "react";
import Layout from "../components/layout";
import { graphql } from "gatsby";
import LearnView from "../components/views/modules";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import SubScribeView from "../components/subscribe";

// markup
const IndexPage = ({ data }: any) => {
  return (
    <Layout meta={data.site.siteMetadata} title="Home" link={"/"}>
      <main className="">
        <div className="mb-6">
          <div>
            Polyglot helps you learn new languages, one word at a time,{" "}
            <span className="mt-1 inline-bloc border-b border-accent">
              on demand!
            </span>
            .
            <div className="mt-1  text-xs">
              {" "}
              Under the hood, Polyglot uses state of the art AI models to
              generate the right curriculum to meet your language learning
              needs!
            </div>
          </div>
        </div>

        <SubScribeView />

        <div className="mt-6 text-sm">Preview the app below.</div>
        <LearnView />
      </main>
    </Layout>
  );
};

export const query = graphql`
  query HomePageQuery {
    site {
      siteMetadata {
        description
        title
      }
    }
  }
`;

export default IndexPage;
